import apiFetch from 'utils/apiFetch';
/* GHG Output Data */
export type GHGOutputData = {
  id: string;
  name_dcycle: string;
  sources: string[];
  mandatory: boolean;
  being_reported: boolean;
  name_es: string;
  name_en: string;
  name_pt: string;
};
export type fetchGHGOutputDataProps = {
  sector: string;
  year: string;
  scope?: string;
};

export const fetchGHGOutputData = async (
  props: fetchGHGOutputDataProps
): Promise<GHGOutputData[]> => {
  try {
    const { sector, year, scope } = props;
    const url = `/sot_organization_ghg_output_data?sector=${encodeURIComponent(
      sector
    )}&year=${encodeURIComponent(year)}${scope ? `&scope=${encodeURIComponent(scope)}` : ''}`;
    const response = await apiFetch('GET', url);
    if (!response || !response.data) {
      throw new Error('Invalid response structure');
    }
    return response.data as GHGOutputData[];
  } catch (error) {
    console.error('Error fetching GHG output data:', error);
    throw new Error('Failed to fetch GHG output data. Please try again later.');
  }
};

/* CSRD Output Data */
export type CSRDOutputData = {
  id: string;
  name_dcycle: string;
  datapoint_id: string;
  mandatory: boolean;
  being_reported: boolean;
  name_es: string;
  name_en: string;
  name_pt: string;
};

export type fetchCSRDOutputDataProps = {
  sector: string;
  year: string;
  esgCategory?: string;
  esgSubcategory?: string;
  mandatory?: boolean;
  beingReported?: boolean;
};

export const fetchCSRDOutputData = async (
  props: fetchCSRDOutputDataProps
): Promise<CSRDOutputData[]> => {
  try {
    const { sector, year, esgCategory, esgSubcategory, mandatory, beingReported } = props;

    const queryParams: Record<string, string> = {
      sector,
      year: String(year),
      ...(esgCategory && { esg_category: esgCategory }),
      ...(esgSubcategory && { esg_subcategory: esgSubcategory }),
      ...(mandatory === true || mandatory === false ? { mandatory: String(mandatory) } : {}),
      ...(beingReported === true || beingReported === false
        ? { beign_reported: String(beingReported) }
        : {})
    };

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    const url = `/sot_organization_csrd_output_data?${queryString}`;

    const response = await apiFetch('GET', url);

    if (!response || !response.data) {
      throw new Error('Invalid response structure');
    }

    return response.data as CSRDOutputData[];
  } catch (error) {
    console.error('Error fetching CSRD output data:', error);
    throw new Error('Failed to fetch CSRD output data. Please try again later.');
  }
};

/* Base Data */
export type BaseData = {
  id: string;
  name_dcycle: string;
  source: string;
  sector: string;
  name_es: string;
  name_en: string;
  name_pt: string;
};
export type fetchBaseDataProps = {
  outputDataId: string;
  sector: string;
  year: string;
};

export const fetchBaseData = async (props: fetchBaseDataProps): Promise<BaseData[]> => {
  try {
    const { outputDataId, sector, year } = props;
    const url = `/sot_organization_base_data?output_data_id=${outputDataId}&sector=${sector}&year=${year}`;
    const response = await apiFetch('GET', url);
    if (!response || !response.data) {
      throw new Error('Invalid response structure');
    }
    return response.data as BaseData[];
  } catch (error) {
    console.error('Error fetching base data:', error);
    throw new Error('Failed to fetch base data. Please try again later.');
  }
};
