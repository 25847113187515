import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../../../../actions/notification';
import { parseDatapointToBackend } from '../../../../../../../adapters/datapoints';
import { FilterDatesContext } from '../../../../../../../context/filterDatesContext';
import useOnChangeValue from '../../../../../../../customHooks/useOnChangeValue';
import useYearOptions from '../../../../../../../customHooks/useYearOptions';
import { createDatapointValue, updateDatapointValue } from '../../../../../../../services/api/esg';
import {
  DataType,
  ModalType,
  modalMapping
} from '../../../../../../../types/entitiesEnums/datapoints';
import { convertStringToDate } from '../../../../../../../utils/convertDates';
import Button from '../../../../../../ui/button/Button';
import ErrorText from '../../../../../../ui/errorText/ErrorText';
import FormButtonSection from '../../../../../../ui/formComponents/formButtonSection/FormButtonSection';
import FormElementHalf from '../../../../../../ui/formComponents/formElementHalf/FormElementHalf';
import FormHeader from '../../../../../../ui/formComponents/formHeader/FormHeader';
import FormWrapper from '../../../../../../ui/formComponents/formWrapper/FormWrapper';
import FormSelect from '../../../../../../ui/formComponents2/formInputs/formSelect/FormSelect';
import { DateComponent } from './components/DateComponent';
import { TextAreaComponent } from './components/TextAreaComponent';
import { TextSelectComponent } from './components/TextSelectComponent';
import { UploadFileComponent } from './components/UploadFileComponent';
import { YearComponent } from './components/YearComponent';
import { YesNoComponent } from './components/YesNoComponent';
import { FrameworkDatapoint } from 'components/dashboard/controlPanel/components/frameworkProgress/components/FrameworkDashboard/components/DatapointListRenderer';
import BlueWrapper from 'components/ui/blueWrapper/BlueWrapper';
import { StarLabel } from 'components/ui/statusLabels/starLabel/StarLabel';
import Icon from 'components/ui/icon/Icon';
import ReadMoreParagraph from 'components/ui/readMoreParagraph/ReadMoreParagraph';
import { get } from 'http';

type Props = {
  datapoint: FrameworkDatapoint;
  edit?: boolean;
  closeModal: () => void;
  editElement: (newValue: FrameworkDatapoint, key?: keyof FrameworkDatapoint) => void;
};

interface FormData extends FrameworkDatapoint {
  year: SelectOptionFormat;
  errors: ErrorType[];
  value: string | boolean | File | undefined;
}

export type FormDataStateESG = {
  formData: FormData;
  setFormData: React.Dispatch<React.SetStateAction<FormData>>;
};

export const ModalDatapoint = ({ datapoint, edit, closeModal, editElement }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esgMetrics.general' });
  const { t: tr, i18n } = useTranslation();
  const yearOptions = useYearOptions();
  const dispatch = useDispatch();

  const { startDate, endDate } = useContext(FilterDatesContext);
  const currentLanguage = i18n.language;
  const getTranslatedName = (datapoint: FrameworkDatapoint, currentLanguage: string) => {
    switch (currentLanguage) {
      case 'es':
        return datapoint.name_es;
      case 'pt':
        return datapoint.name_pt;
      default:
        return datapoint.name_en;
    }
  };
  const [loadingButton, setLoadingButton] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    ...datapoint,
    year: {
      id: '2024',
      name: '2024'
    },
    errors: [],
    value: ''
  });

  useEffect(() => {
    const year = convertStringToDate(startDate).getFullYear();

    setFormData({
      ...datapoint,
      year: {
        id: year.toString(),
        name: year.toString()
      },
      errors: [],
      value: ''
    });
  }, [startDate]);

  const formDataState: FormDataStateESG = {
    formData: formData,
    setFormData: setFormData
  };

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const renderFragmentAccordingToType = (type: DataType) => {
    const componentMap: { [key: string]: JSX.Element } = {
      [ModalType.YesNo]: <YesNoComponent formDataState={formDataState} />,
      [ModalType.Table]: <UploadFileComponent formDataState={formDataState} />,
      [ModalType.Narrative]: <TextAreaComponent formDataState={formDataState} />,
      [ModalType.Numeric]: <TextSelectComponent formDataState={formDataState} />,
      [ModalType.Date]: <DateComponent formDataState={formDataState} />,
      [ModalType.Year]: <YearComponent formDataState={formDataState} />
    };

    const modalType = modalMapping[type];

    return componentMap[modalType] || type;
  };

  const handleSubmit = async () => {
    setLoadingButton(true);
    const response = null;

    /* if (edit || datapoint.id) {
      response = await updateDatapointValue(
        datapoint.organization_data_point_id,
        parseDatapointToBackend(formData),
        datapoint.esg_category as
          | 'double_materiality'
          | 'environmental'
          | 'general_information'
          | 'social'
          | 'governance'
      );
    } else {
      if (formData.type === ModalType.Table) {
        // Create datapoint first with no value to have the id
        response = await createDatapointValue(
          parseDatapointToBackend({ ...formData, value: '' }),
          datapoint.esg_category as
            | 'double_materiality'
            | 'environmental'
            | 'general_information'
            | 'social'
            | 'governance'
        );
      }
      response = await createDatapointValue(
        parseDatapointToBackend(formData),
        datapoint.esg_category as
          | 'double_materiality'
          | 'environmental'
          | 'general_information'
          | 'social'
          | 'governance'
      );
    } */

    setLoadingButton(false);

    if (!response) {
      setFormData((prev) => ({
        ...prev,
        errors: [{ error: 'general', description: tr('error.somethingWentWrong') }]
      }));
      return;
    }

    /* editElement({
      ...formData,
      id: response.data_point_id,
      organization_data_point_id: response.organization_data_point_id ?? response.id,
      relevance: formData.relevance ?? response.relevance,
      status: formData.value ? 'COMPLETE' : 'INCOMPLETE'
    }); */
    dispatch(setNotification(tr('notification.updateDatapoint')));

    closeModal();
  };

  const foundGeneralError = formData.errors.find((error) => error.error === 'general');

  return (
    <>
      <FormHeader title={edit ? t('editModalTitle') : t('addResponseModalTitle')} />
      <p>
        {datapoint.datapoint_id} - {getTranslatedName(datapoint, currentLanguage)}
      </p>
      <div
        style={{
          maxHeight: '350px',
          overflowY: 'auto'
        }}>
        <FormWrapper>
          <FormElementHalf>
            <FormSelect
              icon='/images/icons/calendar.svg'
              label={t('selectYear')}
              onChange={onChangeValue('year')}
              value={formData.year}
              options={yearOptions.years}
              error={formData.errors.find((error) => error.error === 'year')}
              sort={false}
              disabled
            />
          </FormElementHalf>
        </FormWrapper>
        {/* //depending on the incoming datapoint.type, render the second field that corresponds to it */}
        {renderFragmentAccordingToType(datapoint.csrd_data_type as DataType)}
        <div className='flex flex-col p-4 align-start form-wrapper-standard rounded-12 app-bg-color'>
          <div className='flex gap-2 items-center'>
            <Icon icon='multi_star' color={'blocked'} className='m-right-star-icon' />
            <span className='weight-600 secondary-text-color font-12'>
              {t('dcycleAiExampleText')}
            </span>
          </div>
          <ReadMoreParagraph
            id='aiText'
            className='weight-400 font-12 on-light-text-color m-0'
            text={getTranslatedName(datapoint, currentLanguage)}
            amountOfWords={27}
          />
        </div>
      </div>
      <FormButtonSection style={{ marginTop: '1em' }}>
        <Button lookAndFeel='secondary' text={t('cancel')} onClick={closeModal} size='medium' />
        <Button
          lookAndFeel='primary'
          text={t('saveClose')}
          size='medium'
          onClick={handleSubmit}
          loading={loadingButton}
        />
      </FormButtonSection>
      {foundGeneralError && <ErrorText>{foundGeneralError.description}</ErrorText>}
    </>
  );
};
