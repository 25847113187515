import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parseDatapointToBackend } from '../../../../../../../adapters/datapoints';
import { FilterDatesContext } from '../../../../../../../context/filterDatesContext';
import useDebounceValue from '../../../../../../../customHooks/useDebounceValue';
import { createDatapointValue, updateDatapointValue } from '../../../../../../../services/api/esg';
import { EsgMetricsEnhanced } from '../../../../../../../types/entities/esgMetrics';
import { InputSize } from '../../../../../../../types/utilsEnums/input';
import { FilterText } from '../../../../../../layout/NewFilters/FilterText';
import { Filters } from '../../../../../../layout/NewFilters/Filters';
import Button from '../../../../../../ui/button/Button';
import InfiniteList from '../../../../../../ui/infiniteList/InfiniteListV2';
import Modal from '../../../../../../ui/modal/Modal';
import Sorting from '../../../../../../ui/sorting/Sorting';
import TotalLegend from '../../../../../../ui/totalLegend/TotalLegend';
import '../../styles.scss';
import { ModalDatapoint } from '../modal/ModalDatapoint';
import useColumns from './hooks/useColumns';
import useSortingOptions from './hooks/useSortingOptions';
import { CSRDModalPremium } from './components/CSRDModalPremium';
import { useGetDatapoints } from './hooks/useGetDatapoints';
import { useCSRDOutputData } from 'customHooks/api/useSolOrgData';
import { UserContext } from 'context/userContext';
import { FrameworkDatapoint } from 'components/dashboard/controlPanel/components/frameworkProgress/components/FrameworkDashboard/components/DatapointListRenderer';
import Select from 'components/ui/formComponents2/select/Select';
import InputWrapper from 'components/ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import InputText from 'components/ui/formComponents2/inputText/InputText';

type Props = {
  category: string;
  subcategory?: string;
};

export const DatapointsTable = ({ category, subcategory }: Props) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [datapointToEdit, setDatapointToEdit] = useState<FrameworkDatapoint>();
  const [searchValue, setSearchValue] = useState('');
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [mandatoryFilter, setMandatoryFilter] = useState<boolean | undefined>(undefined);
  const [filteredData, setFilteredData] = useState<FrameworkDatapoint[]>([]);
  const [openFilters, setOpenFilters] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const { startDate } = useContext(FilterDatesContext);
  const user = useContext(UserContext);
  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );
  const sector = foundOrganization?.sector;
  const year = startDate.split('/')[2];

  const transformedCategory = category === 'general_information' ? 'general' : category;

  const { csrdOutputData, isLoading } = useCSRDOutputData({
    sector,
    year: year?.toString(),
    esgCategory: transformedCategory,
    esgSubcategory: subcategory
  });
  const columns = useColumns({
    category,
    subcategory,
    setDatapointToEdit,
    handleEditRelevance: () => {
      null;
    }, // Placeholder for now
    setShowPremiumModal,
    language: currentLanguage
  });
  const totalItems = csrdOutputData?.length || 0;

  const debounceSearchValue = useDebounceValue(searchValue, 500);
  const mandatoryFilterOptions = [
    { id: 'all', name: t('esgMetrics.filters.all') },
    { id: 'true', name: t('esgMetrics.filters.mandatory') },
    { id: 'false', name: t('esgMetrics.filters.nonMandatory') }
  ];
  useEffect(() => {
    if (!csrdOutputData) return;

    let filtered = csrdOutputData;

    // Apply the `mandatory` filter
    if (typeof mandatoryFilter === 'boolean') {
      filtered = filtered.filter((datapoint) => datapoint.mandatory === mandatoryFilter);
    }

    // Apply the search filter for `datapoint_id`
    if (debounceSearchValue.trim()) {
      filtered = filtered.filter((datapoint) =>
        datapoint.datapoint_id?.toLowerCase().includes(debounceSearchValue.toLowerCase())
      );
    }

    setFilteredData(filtered);
  }, [csrdOutputData, mandatoryFilter, debounceSearchValue]);

  return (
    <div className='infinite-list-datapoints flex flex-col'>
      <InfiniteList
        fetchData={() => null} // All data is preloaded; no additional fetching needed
        data={filteredData ?? []}
        columns={columns}
        loading={isLoading}
        firstLoading={isLoading}
        total={filteredData.length}
        header={
          <div
            className='flex justify-between items-center'
            style={{
              width: '100%'
            }}>
            <div className='flex items-center gap-2'>
              {/* Search Input */}
              <InputWrapper icon={'/images/icons/search.svg'}>
                <InputText
                  placeholder={t('esgMetrics.filters.search')}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </InputWrapper>

              {/* Select Dropdown for Mandatory Filter */}
              <InputWrapper icon={'/images/icons/filter.svg'}>
                <Select
                  value={
                    mandatoryFilter === undefined
                      ? { id: 'all', name: t('esgMetrics.filters.all') }
                      : mandatoryFilter
                      ? { id: 'true', name: t('esgMetrics.filters.mandatory') }
                      : { id: 'false', name: t('esgMetrics.filters.nonMandatory') }
                  }
                  placeholder={t('esgMetrics.filters.all')}
                  options={mandatoryFilterOptions}
                  onChange={(option) => {
                    if (option.id === 'all') {
                      setMandatoryFilter(undefined);
                    } else {
                      setMandatoryFilter(option.id === 'true');
                    }
                  }}
                />
              </InputWrapper>
            </div>
            {/* Download Button and Total Legend */}
            <div className='flex justify-end items-center'>
              <Button
                style={{
                  width: '7rem',
                  marginRight: '0.66rem'
                }}
                size='tiny'
                lookAndFeel={'primary'}
                disabled
                text={t('esgMetrics.general.download')}
              />
              <TotalLegend total={filteredData.length || 0} loading={false} i18key={'esgMetrics'} />
            </div>
          </div>
        }
      />
      {datapointToEdit && datapointToEdit.id && (
        <Modal.WithPortal
          show={!!datapointToEdit && !!datapointToEdit.id}
          onClose={() => setDatapointToEdit(undefined)}
          width='756px'
          maxWidth='756px'>
          <ModalDatapoint
            datapoint={datapointToEdit}
            edit
            closeModal={() => setDatapointToEdit(undefined)}
            editElement={() => null}
          />
        </Modal.WithPortal>
      )}
      {datapointToEdit && !datapointToEdit.id && (
        <Modal.WithPortal
          show={!!datapointToEdit && !datapointToEdit.id}
          onClose={() => setDatapointToEdit(undefined)}
          width='756px'
          maxWidth='756px'>
          <ModalDatapoint
            datapoint={datapointToEdit}
            closeModal={() => setDatapointToEdit(undefined)}
            editElement={() => null}
          />
        </Modal.WithPortal>
      )}
      {showPremiumModal && (
        <Modal.WithPortal show={showPremiumModal} onClose={() => setShowPremiumModal(false)}>
          <CSRDModalPremium />
        </Modal.WithPortal>
      )}
    </div>
  );
};
