import { useQuery } from '@tanstack/react-query';
import {
  BaseData,
  CSRDOutputData,
  fetchBaseData,
  fetchBaseDataProps,
  fetchCSRDOutputData,
  fetchCSRDOutputDataProps,
  fetchGHGOutputData,
  fetchGHGOutputDataProps,
  GHGOutputData
} from 'services/api/sotOrganizationData';

export const useGHGOutputData = (props: Partial<fetchGHGOutputDataProps>) => {
  const { sector, year } = props;

  const isEnabled = !!sector && !!year;

  const { data, isLoading, error } = useQuery<GHGOutputData[], Error>({
    queryKey: ['ghgOutputData', props],
    queryFn: () =>
      isEnabled
        ? fetchGHGOutputData(props as fetchGHGOutputDataProps)
        : Promise.reject('Missing required parameters'),
    enabled: isEnabled
  });

  return { ghgOutputData: data, isLoading, error };
};

export const useCSRDOutputData = (props: Partial<fetchCSRDOutputDataProps>) => {
  const { sector, year } = props;

  const isEnabled = !!sector && !!year;
  const { data, isLoading, error } = useQuery<CSRDOutputData[], Error>({
    queryKey: ['csrdOutputData', props],
    queryFn: () =>
      isEnabled
        ? fetchCSRDOutputData(props as fetchCSRDOutputDataProps)
        : Promise.reject('Missing required parameters'),
    enabled: isEnabled
  });

  return { csrdOutputData: data, isLoading, error };
};

export const useBaseData = (props: fetchBaseDataProps) => {
  const { data, isLoading, error } = useQuery<BaseData[], Error>({
    queryKey: ['baseData', props],
    queryFn: () => fetchBaseData(props)
  });
  return { baseData: data, isLoading, error };
};
