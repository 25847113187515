import { useTranslation } from 'react-i18next';

import useOnChangeValue from '../../../../../../../../customHooks/useOnChangeValue';
import InputNumber from '../../../../../../../ui/formComponents2/inputNumber/InputNumber';
import InputWrapper from '../../../../../../../ui/formComponents2/inputUtils/inputWrapper/InputWrapper';
import Select from '../../../../../../../ui/formComponents2/select/Select';
import { useGetUnits } from '../hooks/useGetUnits';
import { FormDataStateESG } from '../ModalDatapoint';
type Props = {
  formDataState: FormDataStateESG;
};
export const TextSelectComponent = ({ formDataState }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'esgMetrics.general' });
  const { formData, setFormData } = formDataState;
  const { onChangeValue } = useOnChangeValue({ setFormData });
  const { data: units, isLoading } = useGetUnits(formData.csrd_data_type ?? '');

  /* commented until backend functionality comes 
  const handleOnChangeUnit = (value: SelectOptionFormat) => {
    onChangeValue('value_unit_id')(value.id);
  }; */

  /* const foundUnit = units?.find((unit: SelectOptionFormat) => unit.id === formData.value_unit_id); */

  return (
    <InputWrapper
      label={t('textareaModalLabel')}
      iconV2='input_numeric'
      error={formData.errors.find(
        (elem: ErrorType) => elem.error === 'value' || elem.error === 'unit'
      )}
      className='col-span-1 md-col-span-2'>
      <InputNumber
        value={(formData.value as string) ?? ''}
        onChange={onChangeValue('value')}
        placeholder={t('quantityPlaceholder')}
        max={formData.csrd_data_type === 'percent' ? 100 : undefined}
        min={formData.csrd_data_type === 'percent' ? 0 : undefined}
      />
      {units && units.length > 0 ? (
        <Select
          value={/* foundUnit ??  */ { id: '', name: '' }}
          onChange={/* handleOnChangeUnit */ () => null}
          options={!isLoading ? units : []}
          placeholder={t('unitPlaceholder')}
          loading={isLoading}
        />
      ) : null}
    </InputWrapper>
  );
};
