import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import { FilterDatesContext } from '../../../../context/filterDatesContext';
import { getOrganizationESGStatus } from '../../../../services/api/esg';
import { EsgCategoryCompleteness } from '../../../../types/entities/esgMetrics';
import CardEsg from '../components/CardEsg/CardEsg';
import { useFeatureFlags } from 'customHooks/useFeatureFlags';
import { UserContext } from 'context/userContext';
import { fetchCSRDOutputData } from 'services/api/sotOrganizationData';
interface CategoryResults {
  total: number;
  mandatoryCount: number;
}
const esgCategories = ['general', 'environmental', 'social', 'governance'] as const;
type Category = (typeof esgCategories)[number];
const useData = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'esg' });
  const navigate = useNavigate();
  const flags = useFeatureFlags();
  const { startDate, endDate } = useContext(FilterDatesContext);
  const user = useContext(UserContext);
  const foundOrganization = user?.organizations?.find(
    (org) => org.id === user.selectedOrganization
  );
  const sector = foundOrganization?.sector;
  const [loading, setLoading] = useState<boolean>(false);
  const year = startDate.split('/')[2];
  const [results, setResults] = useState<Record<Category, CategoryResults>>({
    general: { total: 0, mandatoryCount: 0 },
    environmental: { total: 0, mandatoryCount: 0 },
    social: { total: 0, mandatoryCount: 0 },
    governance: { total: 0, mandatoryCount: 0 }
  });
  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      if (!sector || !year) {
        setLoading(false);
        return;
      }
      const resultsPerCategory: Record<Category, CategoryResults> = {
        general: { total: 0, mandatoryCount: 0 },
        environmental: { total: 0, mandatoryCount: 0 },
        social: { total: 0, mandatoryCount: 0 },
        governance: { total: 0, mandatoryCount: 0 }
      };
      try {
        await Promise.all(
          esgCategories.map(async (category) => {
            const data = await fetchCSRDOutputData({ sector, year, esgCategory: category });
            const total = data.length;
            const mandatoryCount = data.filter((item) => item.mandatory).length;
            resultsPerCategory[category] = { total, mandatoryCount };
          })
        );
        setResults(resultsPerCategory);
      } catch (error) {
        console.error('Error fetching CSRD output data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [sector, year]);

  const data = [
    <CardEsg
      key='double-materiality'
      onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS + '/double_materiality')}>
      <>
        <CardEsg.Title title={t('doubleMaterialityTitle')} />
        <CardEsg.Description descriptions={[t('doubleMaterialityDescription')]} />
        {/* commented till back is ready <CardEsg.Completeness completeness={flags?.webinar241024 ? 100 : 0} /> */}
        <CardEsg.Esrs esrs={t('startNow')} />
      </>
    </CardEsg>,
    <CardEsg
      key='general-information'
      onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS + '/general_information')}>
      <>
        <CardEsg.Title title={t('generalInformationTitle')} />
        <CardEsg.Subtitle
          mandatoryDatapoints={results.general.mandatoryCount}
          datapoints={results.general.total}
        />
        <CardEsg.Esrs esrs={'ESRS 2'} />
        {/* <CardEsg.Completeness
          completeness={
            general_information && general_information.total > 0
              ? (100 * general_information?.completed) / general_information.total
              : 0
          }
        /> */}
      </>
    </CardEsg>,
    <CardEsg
      key='ambiental'
      onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS + '/environmental')}>
      <>
        <CardEsg.Title title={t('ambientalTitle')} />
        <CardEsg.Subtitle
          mandatoryDatapoints={results.environmental.mandatoryCount}
          datapoints={results.environmental.total}
        />
        <CardEsg.Esrs esrs={'ESRS E1 E2 E3 E4 E5'} />
        {/* <CardEsg.Completeness
          completeness={
            environmental && environmental.total > 0
              ? (100 * environmental?.completed) / environmental.total
              : 0
          }
        /> */}
      </>
    </CardEsg>,
    <CardEsg key='social' onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS + '/social')}>
      <>
        <CardEsg.Title title={t('socialTitle')} />
        <CardEsg.Subtitle
          mandatoryDatapoints={results.social.mandatoryCount}
          datapoints={results.social.total}
        />
        <CardEsg.Esrs esrs={'ESRS S1 S2 S3 S4'} />
        {/* <CardEsg.Completeness
          completeness={social && social.total > 0 ? (100 * social?.completed) / social.total : 0}
        /> */}
      </>
    </CardEsg>,
    <CardEsg
      key='governance'
      onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS + '/governance')}>
      <>
        <CardEsg.Title title={t('governanceTitle')} />
        <CardEsg.Subtitle
          mandatoryDatapoints={results.governance.mandatoryCount}
          datapoints={results.governance.total}
        />
        <CardEsg.Esrs esrs={'ESRS G1'} />
        {/* <CardEsg.Completeness
          completeness={
            governance && governance.total > 0
              ? (100 * governance?.completed) / governance.total
              : 0
          }
        /> */}
      </>
    </CardEsg>,
    <CardEsg
      key='eu-taxonomy'
      onClick={() => onClickCard(ROUTES.MEASURE_ESG_METRICS + '/eu_taxonomy')}>
      <>
        <CardEsg.Title title={t('euTaxonomy')} />
        <CardEsg.Description descriptions={[t('euTaxonomyDescription')]} />
        <CardEsg.Esrs esrs={t('euTaxonomyObjectives')} />
        {/* <CardEsg.Completeness completeness={0} /> */}
      </>
    </CardEsg>
  ];

  const onClickCard = (url: string) => navigate(url);

  return { data, loading };
};

export default useData;
